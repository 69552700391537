import common from "./common";
import errors from "./errors";
import organizations from "./organizations";
import resellers from "./resellers";
import users from "./users";
import roles from "./roles";
import permissions from "./permissions";
import products from "./products";
import invoices from "./invoices";
import customers from "./customers";
import packages from "./packages";
import subscriptions from "./subscriptions";
import payments from "./payments";
import profile from "./profile";
import contacts from "@/i18n/en/contacts";
import suppliers from "@/i18n/en/suppliers";
import imports from "@/i18n/en/imports";

export default {
  COMMON: common,
  ERRORS: errors,
  USERS: users,
  ORGANIZATIONS: organizations,
  RESELLERS: resellers,
  ROLES: roles,
  PERMISSIONS: permissions,
  PRODUCTS: products,
  INVOICES: invoices,
  CUSTOMERS: customers,
  SUBSCRIPTIONS: subscriptions,
  PACKAGES: packages,
  PAYMENTS: payments,
  PROFILE: profile,
  CONTACTS: contacts,
  SUPPLIERS: suppliers,
  IMPORT: imports,
};
